(function(i, p, w, d, b, t) {

    var roller_ad = {

        adImages: function() {
            var html = '',
                images,
                image;
            if (this.useMobileImage()) {
                images = Math.ceil((w.innerWidth + this.settings.mobile_image_width) / this.settings.mobile_image_width);
                image = this.settings.mobile_image;
            } else {
                images = Math.ceil((w.innerWidth + this.settings.image_width) / this.settings.image_width);
                image = this.settings.image;
            }
            for (var i=0; i<images; i++) {
                html += '<img src="'+ image +'">';
            }
            return html;
        },
        
        closeAd: function(e) {
            if (e.target.classList.contains('roller-ad-close')) {
                var node = d.getElementById('roller-ad-'+roller_ad.id);
                node.removeEventListener('click', roller_ad.closeAd);
                node.parentNode.removeChild(node.parentNode.querySelector('style'));
                node.parentNode.removeChild(node);
                if (roller_ad.close_timer) {
                    w.clearTimeout(roller_ad.close_timer);
                }
            }
        },
        
        init: function() {
            p.parentNode.insertAdjacentHTML('afterend', this.html(this.settings));
            var insertedAd = p.parentNode.parentNode.querySelector('.roller-ad');
            d.getElementById('roller-ad-'+this.id).addEventListener('click', this.closeAd);
            
            if (roller_ad.settings.close_after > 0) {
                roller_ad.close_timer = w.setTimeout(function(){
                    roller_ad.closeAd({target: insertedAd.querySelector('.roller-ad-close')});
                }, roller_ad.settings.close_after * 1000);
            }
        },
        
        scroll: function() {
            if (w.scrollY >= roller_ad.settings.scroll_pos) {
                roller_ad.init();
                d.removeEventListener('scroll', roller_ad.scroll);
            }
        },

        useMobileImage: function() {
            if (w.innerWidth < 768 && this.settings.mobile_image && this.settings.mobile_image_width && this.settings.mobile_image_height) {
                return true;
            }
            return false;
        },

        styleHtml: function() {
            var image_width,
                image_height;
            if (this.useMobileImage()) {
                image_width = this.settings.mobile_image_width;
                image_height = this.settings.mobile_image_height;
            } else {
                image_width = this.settings.image_width;
                image_height = this.settings.image_height;
            }
            html = '<style>' +
                '.roller-ad { width:100%; height:'+ image_height +'px; position:fixed; left:0px; bottom:0px; z-index:1040; }' +
                '@keyframes roller { 0% { transform:translateX(0px); } 100% { transform:translateX(-'+ image_width +'px); } }' +
                '.roller-ad .roller-ad-wrapper { display:flex; width:100%; height:100%; position:absolute; top:0px; left:0px; transform:translateX(-'+ image_width +'px); animation-name:roller; animation-duration:'+ this.settings.speed +'s; animation-timing-function:linear; animation-iteration-count:infinite; }' +
                '.roller-ad:hover .roller-ad-wrapper, .roller-ad .roller-ad-wrapper:hover { animation-play-state:paused; }' +
                '.roller-ad .roller-ad-wrapper img { display:block; margin:0px; padding:0px; }' +
                '.roller-ad .roller-ad-link { display:block; width:100%; height:100%; position:absolute; top:0px; left:0px; z-index:1041; }' +
                '.roller-ad .roller-ad-close { display:block; width:20px; height:20px; position:absolute; z-index:1042; top:-20px; right:0px; font-size:16px; color:#222; opacity:0.8; cursor:pointer; }' +
                '.roller-ad .roller-ad-close svg { pointer-events:none; }' +
            '</style>';
            return html;
        },

        html: function() {
            html = this.styleHtml();
            html += '' +
                '<div id="roller-ad-'+ this.id +'" class="roller-ad">' +
                    '<div class="roller-ad-wrapper">' +
                        this.adImages() +
                    '</div>' +
                    '<a class="roller-ad-link" href="'+ this.settings.click_tag +'" target="_blank"></a>' +
                    '<a class="roller-ad-close">' +
                        '<i class="fas tnt-times"></i>' +
                        '<span class="sr-only">Close</span>' +
                    '</a>' +
                '</div>';

            if (this.settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1"></div>';
            }

            return html;
        },

        load: function(settings) {
            this.settings = settings;
            this.id = this.settings.line_item_id + '-' + this.settings.creative_id;
            this.settings.image_width = parseInt(this.settings.image_width);
            this.settings.image_height = parseInt(this.settings.image_height);
            this.settings.mobile_image_width = parseInt(this.settings.mobile_image_width);
            this.settings.mobile_image_height = parseInt(this.settings.mobile_image_height);
            this.settings.speed = parseInt(this.settings.speed);
            this.settings.show_after = parseInt(this.settings.show_after);
            this.settings.close_after = parseInt(this.settings.close_after);
            this.settings.scroll_pos = parseInt(this.settings.scroll_pos);
            
            var that = this;
            
            if (this.settings.show_after > 0) {
                w.setTimeout(that.init, that.settings.show_after * 1000);
            } else if (this.settings.scroll_pos > 0) {
                d.addEventListener('scroll', that.scroll);
            } else {
                this.init();
            }
        }
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        t.cmds.forEach(function(item) {
            roller_ad.load(item.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);